function setEqualHeight(){
    $('.grid-element-content-header-image').matchHeight();
    $('.grid-element-title').matchHeight();
    $('.grid-element-content-detail').matchHeight();
    $('.prices-small').matchHeight();
}

$(window).on("load",function() {
    setEqualHeight();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(window).bind('scroll', function () {
    var scroll = 150,
        skelBreakpoints = skel.vars.stateId;

    if (skelBreakpoints.search("/large") > -1) {
        scroll = 130;
    }

    if (skelBreakpoints.search("/medium") > -1) {
        scroll = 120;
    }

    if (skelBreakpoints.search("/xsmall") > -1) {
        scroll = 190;
    }
    if ($(window).scrollTop() > 10) {
        $('#header-wrapper').addClass("fixed");
        $('body').addClass("header-fixed");
    } else {
        $('#header-wrapper').removeClass("fixed");
        $('body').removeClass("header-fixed");
    }
    if ($(window).scrollTop() < scroll) {
        $('#header-wrapper').css('top',Math.max(-scroll,-$(window).scrollTop()) + "px");
    } else {
        $('#header-wrapper').css('top',-scroll + "px");
    }
});
